import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { mutate } from 'swr';
import { AuthAndApiContext, ToastsContext } from '../../contexts';
import { useForm } from '../../hooks';
import Button from '../Button';
import Checkbox from '../Checkbox';
import Errors from '../Errors';
import FormGroup from '../FormGroup';
import TextInput from '../TextInput';
import styles from './SignIn.module.css';

type Props = React.PropsWithoutRef<JSX.IntrinsicElements['section']>;

type Form = {
  password: string,
  rememberMe: boolean,
  username: string,
};

export default function SignIn({ className, ...props }: Props) {
  const router = useRouter();
  const intl = useIntl();
  const { api, setUserAccessToken } = useContext(AuthAndApiContext);
  const { addToast } = useContext(ToastsContext);

  const [values, , updateValue, errors, setErrors] = useForm<Form>({
    password: '',
    rememberMe: false,
    username: '',
  });

  return (
    <section {...props} className={classNames(styles.signIn, className)}>
      <form
        onSubmit={async (event) => {
          event.preventDefault();

          const response = await api.post('/users/sign_in/', {}, {
            headers: {
              Authorization: `Basic ${btoa(`${values.username}:${values.password}`)}`,
            },
          });

          const responseJson = await response.json();

          if (!response.ok) {
            setErrors(responseJson);

            return;
          }

          await mutate('/users/me/', responseJson.user);
          setUserAccessToken(responseJson.accessToken);

          addToast({
            content: intl.formatMessage({
              defaultMessage: 'You have been successfully signed in. GLHF!',
            }),
            kind: 'success',
          });

          await router.push(router.query.next !== undefined ? router.query.next.toString() : '/arena');
        }}
      >
        <fieldset>
          {errors.detail !== undefined && (
            <div className={styles.error}>
              {errors.detail}
            </div>
          )}

          <Errors errors={errors.nonFieldErrors} />

          <FormGroup error={errors.username}>
            <TextInput
              id="text-input-username"
              label={intl.formatMessage({ defaultMessage: 'Username' })}
              name="username"
              onChange={updateValue}
              required
              value={values.username}
            />
          </FormGroup>

          <FormGroup error={errors.password}>
            <TextInput
              id="text-input-password"
              label={intl.formatMessage({ defaultMessage: 'Password' })}
              name="password"
              onChange={updateValue}
              required
              type="password"
              value={values.password}
            />
          </FormGroup>

          <FormGroup className={styles.rememberMeFormGroup}>
            <Checkbox
              checked={values.rememberMe}
              id="checkbox-sign-in-remember-me"
              label={intl.formatMessage({ defaultMessage: 'Remember me' })}
              name="rememberMe"
              onChange={updateValue}
            />
          </FormGroup>

          <Button fullWidth type="submit" variant="secondary">
            <FormattedMessage defaultMessage="Sign In" />
          </Button>
        </fieldset>
      </form>

      <div className={styles.postForm}>
        <Link href="/password-recovery">
          <a className={styles.postFormLink}>
            <FormattedMessage defaultMessage="Forgot password?" />
          </a>
        </Link>
      </div>
    </section>
  );
}
