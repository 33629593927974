import classNames from 'classnames';
import React, { useContext, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import useSWR from 'swr';
import { AuthAndApiContext, RamadanCampaignContext } from '../../contexts';
import styles from './DepositCurrencyRate.module.css';
import { ReactComponent as Equality } from './equality.svg';

type Props = Overwrite<React.PropsWithoutRef<JSX.IntrinsicElements['section']>, {
  amount: number | null,
}>;

function calculatePromotion(amount: number): string {
  if (amount === 250) {
    return '300';
  }

  if (amount === 500) {
    return '600';
  }

  return '';
}

export default function DepositCurrencyRate({ amount, className, ...props }: Props) {
  const { user } = useContext(AuthAndApiContext);
  const { isRamadan } = useContext(RamadanCampaignContext);
  const { data: region } = useSWR<Region>(user === null ? null : `/i18n/regions/${user.region}/`);

  const calculatedAmount = useMemo<number>(() => {
    if (amount === null || amount === 0) return 250;

    return amount;
  }, [amount]);

  const inRamadanCampaign = useMemo<boolean>(() => user !== null
    && user.ramadanCampaignUsedAt === null
    && [250, 500].includes(calculatedAmount)
    && isRamadan,
  [calculatedAmount, isRamadan, user]);

  return region === undefined ? null : (
    <section
      {...props}
      className={classNames(styles.depositCurrencyRate,
        inRamadanCampaign && styles.ramadanCampaign,
        className)}
    >
      {inRamadanCampaign && (
        <div className={styles.discount}>
          {calculatedAmount === 250 && '+50'}
          {calculatedAmount === 500 && '+100'}
        </div>
      )}

      <div className={classNames(styles.box, inRamadanCampaign && styles.coin)}>
        <p className={styles.muted}>
          <FormattedMessage defaultMessage="Deposit Amount" />
        </p>

        <h3 className={styles.amount}>
          <FormattedMessage defaultMessage="{amount} Coins" values={{ amount: calculatedAmount }} />
        </h3>

        {inRamadanCampaign && (
          <h2 className={styles.discountAmount}>
            <FormattedMessage
              defaultMessage="{amount} Coins"
              values={{ amount: (calculatePromotion(calculatedAmount)) }}
            />
          </h2>
        )}
      </div>

      <div className={styles.equality}>
        <Equality />
      </div>

      <div className={styles.box}>
        <p className={styles.muted}>
          <FormattedMessage defaultMessage="Total Amount" />
        </p>

        <h3 className={styles.amount}>
          {`${calculatedAmount * region.exchangeRate} ${region.currencyCode}`}
        </h3>
      </div>

      {inRamadanCampaign && (
        <div className={styles.ramadanCampaign}>
          <h4 className={styles.title}>
            <FormattedMessage defaultMessage="Ramadan Campaign" />
          </h4>

          <p className={styles.disclaimer}>
            <FormattedMessage defaultMessage="Between 13th March - 15th May, you get 50 GA Coin for your deposit of 250 GA Coin and 100 GA Coin for your deposit of 500 GA Coin for free! You can only use this campaign once and you need to finish at least 2 paid duels to be able to request withdraw." />
          </p>
        </div>
      )}
    </section>
  );
}
