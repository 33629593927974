import classNames from 'classnames';
import React, { useContext, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import useSWR from 'swr';
import { AnalyticsContext, AuthAndApiContext } from '../../contexts';
import { sendGAEvent } from '../../helpers';
import { ReactComponent as Card } from '../DepositPaymentMethod/card.svg';
import Errors from '../Errors';
import FormGroup from '../FormGroup';
import RadioInput from '../RadioInput';
import TextInput from '../TextInput';
import { ReactComponent as Info } from './info.svg';
import N11 from './n11.png';
import Papara from './papara.png';
import styles from './WithdrawPaymentMethod.module.css';

type PaparaUserInfo = {
  paparaId: string | null,
  tcIdNumber: string | null,
};

type Props = Overwrite<React.PropsWithoutRef<JSX.IntrinsicElements['section']>, {
  errors: Record<string, Array<string>>,
  paparaUserInfo: PaparaUserInfo,
  paymentMethod: PaymentProvider | null,
  setPaparaUserInfo: (paymentMethod: Props['paparaUserInfo']) => void,
  setPaymentMethod: (paymentMethod: Props['paymentMethod']) => void,
}>;

export default function WithdrawPaymentMethod({
  className,
  errors,
  paparaUserInfo,
  paymentMethod,
  setPaparaUserInfo,
  setPaymentMethod,
  ...props
}: Props) {
  const intl = useIntl();
  const { category } = useContext(AnalyticsContext);
  const { user } = useContext(AuthAndApiContext);
  const { data: region } = useSWR<Region>(user === null ? null : `/i18n/regions/${user.region}/`);
  const { data: wallet } = useSWR<WalletDetail>('/wallet/wirecard/');
  const { data: papara } = useSWR<PaparaWallet>('/wallet/papara/');

  useEffect(() => {
    if (paymentMethod !== null) {
      sendGAEvent({ category, event: 'Set Provider', label: 'Withdraw', value: paymentMethod || '' });
    }
  }, [paymentMethod]);

  return region === undefined ? null : (
    <section {...props} className={classNames(styles.withdrawPaymentMethod, className)}>
      <div className={styles.header}>
        <h4><FormattedMessage defaultMessage="How You Get Paid" /></h4>
      </div>

      <div className={styles.body}>
        {wallet !== undefined && region.withdrawProviders.includes('wirecard_emoney') && (
          <div className={styles.wirecard}>
            <RadioInput
              checked={paymentMethod === 'wirecard_emoney'}
              className={styles.radio}
              id="wirecard_emoney"
              label={(
                <div className={styles.content}>
                  <Card />

                  <div className={styles.info}>
                    <p className={styles.muted}>
                      <FormattedMessage defaultMessage="Default Method" />
                    </p>

                    <p>
                      IBAN
                      {' '}
                      {/* {wallet.iban} */}
                    </p>
                  </div>
                </div>
              )}
              onChange={(e) => setPaymentMethod(e.target.value as PaymentProvider)}
              required
              value="wirecard_emoney"
            />

            <div className={styles.disclaimer}>
              <Info />

              <p>
                <FormattedMessage defaultMessage="If you want to change your bank account, please contact us." />
              </p>
            </div>
          </div>
        )}

        {region.withdrawProviders.includes('papara_masspayment') && (
          <div>
            <RadioInput
              checked={paymentMethod === 'papara_masspayment'}
              className={styles.radio}
              id="papara_masspayment"
              label={(
                <div className={styles.content}>
                  <img alt="papara" className={styles.papara} src={Papara} />
                </div>
              )}
              onChange={(e) => setPaymentMethod(e.target.value as PaymentProvider)}
              required
              value="papara_masspayment"
            />

            {paymentMethod === 'papara_masspayment' && (
              <>
                <div className={styles.paparaInputs}>
                  <FormGroup error={errors.tckn}>
                    <TextInput
                      disabled={papara !== undefined && papara.isVerified}
                      id="papara-wallet-tc-id-number"
                      label={intl.formatMessage({ defaultMessage: 'TC ID Number' })}
                      name="tcIdNumber"
                      onChange={(e) => setPaparaUserInfo({ ...paparaUserInfo, tcIdNumber: e.target.value })}
                      required={paymentMethod === 'papara_masspayment'}
                      size="large"
                      value={paparaUserInfo.tcIdNumber || ''}
                    />
                  </FormGroup>

                  <FormGroup error={errors.paparaId}>
                    <TextInput
                      disabled={papara !== undefined && papara.isVerified}
                      id="papara-wallet-papara-id"
                      label={intl.formatMessage({ defaultMessage: 'Papara Id' })}
                      name="paparaId"
                      onChange={(e) => setPaparaUserInfo({ ...paparaUserInfo, paparaId: e.target.value })}
                      required={paymentMethod === 'papara_masspayment'}
                      size="large"
                      value={paparaUserInfo.paparaId || ''}
                    />
                  </FormGroup>
                </div>

                {papara !== undefined && papara.isVerified && (
                  <div className={styles.disclaimer}>
                    <Info />

                    <p>
                      <FormattedMessage defaultMessage="If you want to change your Papara account, please contact us." />
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
        )}

        {region.withdrawProviders.includes('n11') && (
          <RadioInput
            checked={paymentMethod === 'n11'}
            className={styles.radio}
            id="n11"
            label={(
              <div className={styles.content}>
                <img alt="n11" className={styles.n11} src={N11} />
              </div>
            )}
            onChange={(e) => setPaymentMethod(e.target.value as PaymentProvider)}
            required
            value="n11"
          />
        )}

        <Errors errors={errors.provider} />
      </div>
    </section>
  );
}
