import classNames from 'classnames';
import Link from 'next/link';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Container, { Props as ContainerProps } from '../Container';
import Logo from '../Logo';
import styles from './Footer.module.css';

import { ReactComponent as Discord } from './discord.svg';
import { ReactComponent as Facebook } from './facebook.svg';
import { ReactComponent as Instagram } from './instagram.svg';
import { ReactComponent as Twitter } from './twitter.svg';

export type Props = ContainerProps;

export default function Footer({ className, ...props }: Props): JSX.Element {
  return (
    <footer {...props} className={classNames(className, styles.footer)}>
      <Container className={styles.container}>
        <div className={classNames(styles.column, styles.brand)}>
          <Logo className={styles.logo} />
          <p className={styles.notice}>
            <FormattedMessage
              defaultMessage="Gamer Arena is not endorsed by, directly affiliated with, maintained or sponsored by Electronic Arts,
            Activision Blizzard, Valve Corporation, Miniclip, Epic Games, Blizzard Entertainment, Microsoft, Xbox,
            Sony or Playstation. All content, games titles, trade names and/or trade dress, trademarks, artwork and
            associated imagery are trademarks and/or copyright material of their respective owners."
            />
          </p>
          <p className={styles.copyright}>
            {`© ${new Date().getFullYear()} Gamer Arena`}
          </p>
        </div>
        <div className={classNames(styles.column, styles.menu)}>
          <nav>
            <Link href="/">
              <a>
                <FormattedMessage defaultMessage="Homepage" />
              </a>
            </Link>
            <Link href="/privacy-policy">
              <a>
                <FormattedMessage defaultMessage="Privacy Policy" />
              </a>
            </Link>
            <a
              href="mailto:info@gamerarena.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FormattedMessage defaultMessage="Corporate Contact" />
            </a>
            <a
              href="http://help.gamerarena.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FormattedMessage defaultMessage="Help & Support" />
            </a>
            <Link href="/terms-of-service">
              <a>
                <FormattedMessage defaultMessage="Terms of Service" />
              </a>
            </Link>
            {/* <Link href="/blog"> */}
            {/* <a> */}
            {/*   <FormattedMessage defaultMessage="Blog" /> */}
            {/* </a> */}
            {/* </Link> */}
          </nav>

          <div className={styles.social}>
            <a
              aria-label="instagram"
              className={styles.socialLink}
              href="https://www.instagram.com/gamerarena_com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Instagram />
            </a>

            <a
              aria-label="facebook"
              className={styles.socialLink}
              href="https://www.facebook.com/gamerarenacom/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Facebook />
            </a>

            <a
              aria-label="twitter"
              className={styles.socialLink}
              href="https://twitter.com/gamerarena_com"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Twitter />
            </a>

            <a
              aria-label="discord"
              className={styles.socialLink}
              href="https://discord.com/invite/SFhnqCd"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Discord />
            </a>
          </div>
        </div>
      </Container>
    </footer>
  );
}
