import classNames from 'classnames';
import dayjs from 'dayjs';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useContext, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMeasure } from 'react-use';
import useSWR, { mutate } from 'swr';
import { AnalyticsContext, AuthAndApiContext, ToastsContext } from '../../contexts';
import { sendGAEvent } from '../../helpers';
import { useForm } from '../../hooks';
import Button from '../Button';
import Checkbox from '../Checkbox';
import DateInput from '../DateInput';
import Errors from '../Errors';
import FormGroup from '../FormGroup';
import Select from '../Select';
import TextInput from '../TextInput';
import styles from './SignUp.module.css';

type Props = React.PropsWithoutRef<JSX.IntrinsicElements['div']>;

type Form = Pick<AuthUser, 'country' | 'email' | 'fullName' | 'language' | 'username'> & {
  dateOfBirth: string,
  termsAgreed: boolean,
  password: string,
  password2: string,
  region: number | null,
};

export default function SignUp({ className, ...props }: Props) {
  const router = useRouter();
  const intl = useIntl();
  const [signUp, { width }] = useMeasure<HTMLDivElement>();
  const { category } = useContext(AnalyticsContext);
  const { api, setUserAccessToken } = useContext(AuthAndApiContext);
  const { addToast } = useContext(ToastsContext);

  const { data: regions } = useSWR<Region[]>('/i18n/regions/');
  const [termsAgreed2, setTermsAgreed2] = useState<boolean>(false);

  const [values, , updateValue, errors, setErrors] = useForm<Form>({
    country: 1,
    dateOfBirth: '',
    email: '',
    fullName: '',
    language: 1,
    password: '',
    password2: '',
    region: null,
    termsAgreed: false,
    username: '',
  });

  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const gridClassName = useMemo<string[]>(() => {
    const c = [styles.grid];

    if (width > 530) {
      c.push(styles.gridWide);
    }

    return c;
  }, [width]);

  return (
    <div
      {...props}
      className={classNames(
        styles.signUp,
        className,
      )}
      ref={signUp}
    >
      <form
        onSubmit={async (event) => {
          event.preventDefault();

          setIsDisabled(true);

          const response = await api.post('/users/sign_up/', values);
          const responseJson = await response.json();

          if (response.ok) {
            setUserAccessToken(responseJson.accessToken);
            await mutate('/users/me/', responseJson.user);

            sendGAEvent({ category, event: 'Sign Up' });

            addToast({
              content: intl.formatMessage({
                defaultMessage: 'We have sent you an e-mail containing the instructions to validate your e-mail address.',
              }),
              kind: 'success',
            });

            await router.push('/arena');
          } else {
            setErrors(responseJson);
          }

          setIsDisabled(false);
        }}
      >
        <fieldset disabled={isDisabled}>
          <Errors errors={errors.nonFieldErrors} />

          <FormGroup error={errors.fullName}>
            <TextInput
              id="text-input-full-name"
              label={intl.formatMessage({ defaultMessage: 'Full Name' })}
              name="fullName"
              onChange={updateValue}
              required
              value={values.fullName}
            />
          </FormGroup>

          <FormGroup error={errors.email}>
            <TextInput
              id="text-input-email"
              label={intl.formatMessage({ defaultMessage: 'E-mail Address' })}
              name="email"
              onChange={updateValue}
              required
              type="email"
              value={values.email}
            />
          </FormGroup>

          <FormGroup error={errors.username}>
            <TextInput
              id="text-input-username"
              label={intl.formatMessage({ defaultMessage: 'Username' })}
              name="username"
              onChange={updateValue}
              required
              value={values.username}
            />
          </FormGroup>

          <div className={classNames(gridClassName)}>
            <FormGroup className={styles.gridFormGroup} error={errors.password}>
              <TextInput
                id="text-input-password"
                label={intl.formatMessage({ defaultMessage: 'Password' })}
                name="password"
                onChange={updateValue}
                required
                type="password"
                value={values.password}
              />
            </FormGroup>

            <FormGroup className={styles.gridFormGroup} error={errors.password2}>
              <TextInput
                id="text-input-password2"
                label={intl.formatMessage({ defaultMessage: 'Password (again)' })}
                name="password2"
                onChange={updateValue}
                required
                type="password"
                value={values.password2}
              />
            </FormGroup>
          </div>

          <FormGroup className={styles.gridFormGroup} error={errors.region}>
            <Select
              id="select-sign-up-region"
              label={intl.formatMessage({ defaultMessage: 'Region' })}
              name="region"
              onChange={(event) => updateValue('region', parseInt(event.target.value))}
              required
              value={values.region || ''}
            >
              {values.region === null && (
                <option aria-label="region" disabled value="" />
              )}

              {regions !== undefined && (
                <>
                  {regions.map((region) => (
                    <option key={region.id} value={region.id}>{region.name}</option>
                  ))}
                </>
              )}
            </Select>
          </FormGroup>

          <div className={classNames(gridClassName)}>
            <FormGroup className={styles.gridFormGroup} error={errors.dateOfBirth}>
              <DateInput
                dateFormat="MM/dd/yyyy"
                id="date-input-sign-up"
                label={intl.formatMessage({ defaultMessage: 'Date of Birth' })}
                onChange={(v) => {
                  if (v === null) {
                    updateValue('dateOfBirth', '');
                  }

                  if (v instanceof Date) {
                    updateValue('dateOfBirth', dayjs(v).format('YYYY-MM-DD'));
                  }
                }}
                onSelect={(v) => {
                  updateValue('dateOfBirth', dayjs(v).format('YYYY-MM-DD'));
                }}
                selected={values.dateOfBirth === '' ? null : dayjs(values.dateOfBirth, 'YYYY-MM-DD').toDate()}
                showMonthDropdown
                showYearDropdown
              />
            </FormGroup>

            {/* <FormGroup className={styles.gridFormGroup} error={errors.language}> */}
            {/*  <Select */}
            {/*    id="select-language" */}
            {/*    label={intl.formatMessage({ defaultMessage: 'Language' })} */}
            {/*    name="language" */}
            {/*    onChange={updateValue} */}
            {/*    required */}
            {/*  > */}
            {/*    <option value="tr"> */}
            {/*      {intl.formatMessage({ defaultMessage: 'Turkish' })} */}
            {/*    </option> */}

            {/*    <option value="en"> */}
            {/*      {intl.formatMessage({ defaultMessage: 'English' })} */}
            {/*    </option> */}
            {/*  </Select> */}
            {/* </FormGroup> */}
          </div>

          <FormGroup error={errors.termsAgreed}>
            <Checkbox
              checked={values.termsAgreed}
              className={styles.conditions}
              id="checkbox-terms-agreed"
              label={(
                <FormattedMessage
                  defaultMessage="By checking this box I agree to Gamer Arena's <termsOfService>Terms of Service</termsOfService> and <privacyPolicy>Privacy Policy</privacyPolicy>. Also I agree that I am above 13, and to participate in paid duels I have to be above 18."
                  values={{
                    privacyPolicy: (...chunks: string[]) => (
                      <Link href="/privacy-policy">
                        <a target="_blank">{chunks}</a>
                      </Link>
                    ),
                    termsOfService: (...chunks: string[]) => (
                      <Link href="/terms-of-service">
                        <a target="_blank">{chunks}</a>
                      </Link>
                    ),
                  }}
                />
              )}
              name="termsAgreed"
              onChange={updateValue}
              required
            />
          </FormGroup>

          <FormGroup>
            <Checkbox
              checked={termsAgreed2}
              className={styles.conditions}
              id="checkbox-terms-agreed2"
              label={(
                <FormattedMessage
                  defaultMessage="I hereby confirm that I don't have another Gamer Arena account. Also I acknowledge that my account will be banned if I use a shared device that accessed another Gamer Arena account before."
                />
              )}
              name="termsAgreed2"
              onChange={(event) => setTermsAgreed2(event.target.checked)}
              required
            />
          </FormGroup>

          <Button
            className={styles.button}
            type="submit"
            variant="secondary"
          >
            <FormattedMessage defaultMessage="Free Signup" />
          </Button>
        </fieldset>
      </form>
    </div>
  );
}
