import { isEqual } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import useSWR from 'swr';
import { AuthAndApiContext, RegionContext } from '../../contexts';
import ActivityIndicator from '../ActivityIndicator';

type Props = {
  children: React.ReactNode,
};

export default function RegionHandler({ children }: Props) {
  const { inProgress, user } = useContext(AuthAndApiContext);
  const { data: regions } = useSWR<Region[]>('/i18n/regions/');
  const [region, setRegion] = useState<Region | null>(null);

  useEffect(() => {
    if (inProgress) return;

    let newRegion: Region | null = region;

    if (regions !== undefined) {
      if (user !== null) {
        newRegion = regions.find((r) => r.id === user.region) || null;
      } else if (region === null) {
        newRegion = regions.find((r) => r.name === 'TR') || regions[0] || null;
      }
    }

    if (!isEqual(region, newRegion)) {
      setRegion(newRegion);
    }
  }, [inProgress, region, regions, user]);

  return region === null ? (
    <ActivityIndicator takeOver />
  ) : (
    <RegionContext.Provider value={{ region, setRegion }}>
      {children}
    </RegionContext.Provider>
  );
}
