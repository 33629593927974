import Head from 'next/head';
import React, { useCallback, useContext, useEffect } from 'react';
import { AuthAndApiContext, BreakpointContext } from '../../contexts';

export default function CrispHandler() {
  const { device } = useContext(BreakpointContext);
  const { user } = useContext(AuthAndApiContext);

  const handleBreakpointDevice = useCallback(() => {
    if (device === 'desktop') {
      window?.$crisp?.push(['do', 'chat:show']);
    } else {
      window?.$crisp?.push(['do', 'chat:hide']);
    }
  }, [device]);

  useEffect(() => {
    if (device !== 'desktop') {
      window?.$crisp?.push(['on', 'chat:closed', () => handleBreakpointDevice()]);
    }

    return () => window?.$crisp?.push(['off', 'chat:closed']);
  }, [device]);

  useEffect(() => {
    if (user === null) {
      window?.$crisp?.push(['do', 'session:reset']);
      window?.$crisp?.push(['do', 'chat:hide']);
    } else {
      handleBreakpointDevice();

      window?.$crisp?.push(['set', 'user:email', user.email]);
      window?.$crisp?.push(['set', 'user:nickname', user.username]);
    }
  }, [handleBreakpointDevice, user]);

  return (
    <Head>
      { /* eslint-disable react/no-danger */}
      <script
        dangerouslySetInnerHTML={{
          __html: 'window.$crisp = window.$crisp || [];',
        }}
      />
      { /* eslint-enable */}
    </Head>
  );
}
