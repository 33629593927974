import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Markdown from '../Markdown';
import styles from './LeaderboardTournamentRules.module.css';

type Props = Overwrite<React.PropsWithoutRef<JSX.IntrinsicElements['section']>, {
  contents: string,
}>;

export default function LeaderboardTournamentRules({ className, contents, ...props }: Props) {
  return (
    <section {...props} className={classNames(styles.leaderboardTournamentRules, className)}>
      <div className={styles.header}>
        <h4><FormattedMessage defaultMessage="Tournament Rules" /></h4>
      </div>

      <div className={styles.body}>
        <Markdown className={styles.markdown}>
          {contents}
        </Markdown>
      </div>
    </section>
  );
}
