import React from 'react';
import { FormattedMessage } from 'react-intl';
import LanguageSelector from '../LanguageSelector';
import Logo from '../Logo';
import styles from './MaintenancePage.module.css';

export default function MaintenancePage() {
  return (
    <div className={styles.maintenancePage}>
      <div className={styles.shade} />

      <section className={styles.content}>
        <Logo className={styles.logo} />

        <div className={styles.body}>
          <h1 className={styles.title}>
            <FormattedMessage defaultMessage="Gamer Arena v3 is coming!" />
          </h1>

          <div className={styles.description}>
            <p>
              <FormattedMessage defaultMessage="We have re-done everything from scratch! Rank based matchmaking, a profile page where you can showcase how good you are, a big re-design, much better performance and a much easier user experience..." />
            </p>

            <p>
              <FormattedMessage defaultMessage="We are thrilled to show you what we have been working on for months. Just one catch, we will be down (in maintenance) for around 5 hours today, but the wait will be worth it :)" />
            </p>

            <p>
              <FormattedMessage
                defaultMessage="If you need to, you can always <contact>reach out to us</contact>, otherwise we'll be back shortly."
                values={{
                  contact: (...chunks: string[]) => (
                    <a href="https://help.gamerarena.com/">{chunks}</a>
                  ),
                }}
              />
            </p>
          </div>
        </div>

        <div className={styles.language}>
          <LanguageSelector />
        </div>
      </section>
    </div>
  );
}
