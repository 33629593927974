import classNames from 'classnames';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import useSWR from 'swr';
import { AuthAndApiContext } from '../../contexts';
import Checkbox from '../Checkbox';
import FormGroup from '../FormGroup';
import RadioInput from '../RadioInput';
import { ReactComponent as Card } from './card.svg';
import styles from './DepositPaymentMethod.module.css';
import { ReactComponent as Paypal } from './paypal.svg';

type Props = Overwrite<React.PropsWithoutRef<JSX.IntrinsicElements['section']>, {
  error: Array<string>,
  paymentMethod: DepositProvider | null,
  termsAgreed: boolean,
  termsAgreedError: Array<string>,
  setPaymentMethod: React.Dispatch<React.SetStateAction<Props['paymentMethod']>>,
  setTermsAgreed: React.Dispatch<React.SetStateAction<Props['termsAgreed']>>,
}>;

export default function DepositPaymentMethod({
  className,
  error,
  paymentMethod,
  setPaymentMethod,
  setTermsAgreed,
  termsAgreed,
  termsAgreedError,
  ...props
}: Props) {
  const { user } = useContext(AuthAndApiContext);
  const { data: region } = useSWR<Region>(user === null ? null : `/i18n/regions/${user.region}/`);

  return region === undefined ? null : (
    <section {...props} className={classNames(styles.depositPaymentMethod, className)}>
      <div className={styles.header}>
        <h3 className={styles.title}>
          <FormattedMessage defaultMessage="Choose Payment Method" />
        </h3>

        <p className={styles.muted}>
          <FormattedMessage defaultMessage="You will be directed to Wirecard to add your payment information." />
        </p>
      </div>

      <div className={styles.wrapper}>
        <FormGroup className={styles.formGroup} error={error}>
          {region.depositProviders.includes('wirecard_pos') && (
            <RadioInput
              checked={paymentMethod === 'wirecard_pos'}
              className={styles.radio}
              id="wirecard_pos"
              label={(
                <div className={styles.content}>
                  <Card />

                  <div className={styles.info}>
                    <p className={styles.muted}>
                      <FormattedMessage defaultMessage="Payment Method" />
                    </p>

                    <p>
                      <FormattedMessage defaultMessage="Credit Card or Bank Card" />
                    </p>
                  </div>
                </div>
              )}
              onChange={(e) => setPaymentMethod(e.target.value as DepositProvider)}
              value="wirecard_pos"
            />
          )}

          {region.depositProviders.includes('paypal_checkout') && (
            <RadioInput
              checked={paymentMethod === 'paypal_checkout'}
              className={styles.radio}
              id="paypal_checkout"
              label={(
                <div className={styles.content}>
                  <Paypal className={styles.paypal} />

                  <div className={styles.info}>
                    <p className={styles.muted}>
                      <FormattedMessage defaultMessage="Payment Method" />
                    </p>

                    <p>PayPal</p>
                  </div>
                </div>
              )}
              onChange={(e) => setPaymentMethod(e.target.value as DepositProvider)}
              value="paypal_checkout"
            />
          )}
        </FormGroup>

        <div className={styles.terms}>
          <FormGroup className={styles.formGroup} error={termsAgreedError}>
            <Checkbox
              checked={termsAgreed}
              className={styles.conditions}
              id="checkbox-deposit-terms-agreed"
              label={(
                <p className={styles.label}>
                  <FormattedMessage
                    defaultMessage="I confirm that I’m 18 years of age and older. By clicking the button below I accept all the legal responsibilities."
                  />
                </p>
              )}
              name="depositTermsAgreed"
              onChange={(e) => setTermsAgreed(e.target.checked)}
              required
            />
          </FormGroup>
        </div>
      </div>
    </section>
  );
}
