import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { FormattedMessage } from 'react-intl';
import useSWR from 'swr';
import Markdown from '../Markdown';
import styles from './DuelRules.module.css';
import { ReactComponent as Plus } from './plus.svg';

type Props = Overwrite<React.PropsWithoutRef<JSX.IntrinsicElements['section']>, {
  duel: HydratedDuel,
}>;

const duelRules = `
### Hazır Olma
Düello kabul edildikten sonra iki tarafın da maça hazır olduğunu bildirmesi gerekmektedir. 
Bir taraf hazır olduğunu bildirdikten sonra rakibin de hazır olması için 15 dakika zaman tanınır, 
aksi takdirde maç başlamaz, hazır olma durumları sıfırlanır ve hazır olma sürecine baştan başlamak 
gerekir.

### Maça Başlama

İki taraf da hazır olduğunda maç başlar. Oyundan rakibini ekleyerek düello modu 
kurallarına uygun oyunu oluşturmak ev sahibinin sorumluluğundadır. Rakibinizin oyun ismini 
düello sayfasından görebilirsiniz. Karşılaşma oynandıktan sonra sonuç ekranında ekran resmi almak 
bütün kullanıcıların sorumluluğudur.

### Skor Girme

- Bizim tarafımızdan belirlenen maç süresi bittiğinde düello sayfasında skor girme butonu çıkar.
- Katılımcılardan biri skor girdiğinde karşı tarafa ‘kabul et’ ve ‘reddet’ şeklinde bildirim gider 
ve aksiyon alması için 1 saat süre verilir.
- Karşı taraf reddederse bu sefer reddeden tarafa sorulur ve aynı şekilde karşıya bildirim gider. 
İki taraf da birbirinin skor bildirimini kabul etmezse maç itiraz merkezine taşınır.
- Skor bildiriminin ardından verilen 1 saat süre içinde karşı taraf aksiyon almazsa, girilen skor kabul edilir ve maç sonuçlanır.

### Yasaklar

- Yanlış skor girilmesi veya doğru girilen skorun reddedilmesi durumunda ceza uygulanır. İlk yanlış skor işleminde 
uyarı, 2. yanlış işleminizde 50 kredi ceza alır ve 3. yanlış işleminizde Gamer Arena’dan kalıcı olarak uzaklaştırılırsınız.
- Rakibinize karşı kışkırtıcı konuşmak ve küfür etmek kesinlikle yasaktır, yapanlar direkt olarak diskalifiye edilecektir. 
Böyle bir davranışa maruz kalırsanız, ekran görüntüsü alarak adminlere bildirebilirsiniz.
- Düello ve site kurallarına uymak bütün kullanıcıların sorumluluğudur. Uyulmadığı durumlarda yaptırım uygulanabilir.
`;

export default function DuelRules({ className, duel, ...props }: Props) {
  const [isDuelRulesOpen, setIsDuelRulesOpen] = useState<boolean>(false);
  const [isGameModeRulesOpen, setIsGameModeRulesOpen] = useState<boolean>(true);
  const { data: gameMode } = useSWR<GameMode>(`/games/game_modes/${duel.gameMode}/`);

  useEffect(() => {
    function onHashChange(): void {
      if (window.location.hash === '#duelRules' && !isDuelRulesOpen) {
        setIsDuelRulesOpen(true);
      }
    }

    window.addEventListener('hashchange', onHashChange);

    return () => window.removeEventListener('hashchange', onHashChange);
  }, []);

  return (
    <section {...props} className={classNames(className, styles.duelRules)}>
      {gameMode === undefined ? null : (
        <div className={classNames(styles.rules, isGameModeRulesOpen && styles.active)}>
          <button
            className={styles.header}
            onClick={() => setIsGameModeRulesOpen((prev) => (!prev))}
            type="button"
          >
            <p>
              <FormattedMessage defaultMessage="Game Mode Rules" />
            </p>

            <Plus />
          </button>

          <AnimateHeight
            duration={150}
            height={isGameModeRulesOpen ? 'auto' : 0}
          >
            <div className={styles.content}>
              <Markdown className={styles.markdown}>
                {gameMode.rules}
              </Markdown>
            </div>
          </AnimateHeight>
        </div>
      )}

      <div className={classNames(styles.rules, isDuelRulesOpen && styles.active)} id="duelRules">
        <button
          className={styles.header}
          onClick={() => setIsDuelRulesOpen((prev) => (!prev))}
          type="button"
        >
          <p>
            <FormattedMessage defaultMessage="Duel Rules" />
          </p>

          <Plus />
        </button>

        <AnimateHeight
          duration={150}
          height={isDuelRulesOpen ? 'auto' : 0}
        >
          <div className={styles.content}>
            <Markdown className={styles.markdown}>
              {duelRules}
            </Markdown>
          </div>
        </AnimateHeight>
      </div>
    </section>
  );
}
